import React, { Component, Fragment } from "react";
import Menu from "../AntMenu/AntMenu";
import classes from "./Content.module.less";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import ChangePassword from "../Dialogs/ChangePassword/ChangePassword";
import BigLoader from "../UI/BigLoader/BigLoader";
import history from "../../history";
import { Modal, Menu as AntMenu, Dropdown, Avatar } from "antd";
import LanguageSelector from "../UI/LanguajeSelector/LanguajeSelector";
import {
  LanguageProvider,
  LanguageConsumer,
} from "../../context/languageContext";
import AppSelect from "../UI/AppSelect/AppSelect";
import GetText from "../UI/Text/Text";
const confirm = Modal.confirm;
class Content extends Component {
  state = {
    displayConfirmationDialog: false,
    displayPasswordDialog: false,
    shareAccessDialog: false,
    email_to_impersonate: null,
    collapsed: false,
  };

  render() {
    let menuOptions = (
      <Fragment>
        <AntMenu>
          <AntMenu.Item
            onClick={() =>
              history.push(`/settings/profile/${this.props.appId}`)
            }
          >
            <GetText id="settingsMenuItem" />
          </AntMenu.Item>
          <LanguageSelector />
          <AntMenu.Item>
            <LanguageConsumer>
              {(value) => (
                <span onClick={() => this.promptLogOut(value.language.id)}>
                  <GetText id="logOutMenuItem" />
                </span>
              )}
            </LanguageConsumer>
          </AntMenu.Item>
        </AntMenu>
      </Fragment>
    );

    return !this.props.apps ? (
      <BigLoader loading />
    ) : (
      <Fragment>
        <LanguageProvider>
          <div className={classes.TopMenu}>
            <div className={classes.LogOut}>
              <Dropdown overlay={menuOptions} placement="bottomLeft">
                {localStorage.imageLocation ? (
                  <Avatar src={localStorage.imageLocation} />
                ) : (
                  <Avatar icon="user" />
                )}
              </Dropdown>
            </div>
          </div>
          <div className={classes.Container}>
            <Menu contentCollapsed={this.setCollapsedContent} {...this.props} />
            <div className={classes.content}>
              <div className={classes.title}>
                <h1 className={classes.regularTitle}>{this.props.title}</h1>
                {this.props.hideAppSelector ? (
                  <h1>{this.props.title}</h1>
                ) : (
                  <AppSelect {...this.props} />
                )}
              </div>
              <div className={classes.children}>
                {!this.props.hideAppSelector ? (
                  <h1
                    className={[
                      classes.responsiveTitle,
                      this.props.hasTabs ? classes.hasTabs : null,
                    ].join(" ")}
                  >
                    {this.props.title}
                  </h1>
                ) : null}
                {this.props.children}
              </div>
            </div>
          </div>
          <ConfirmationDialog
            display={this.state.displayConfirmationDialog}
            close={this.closeConfirmationDialog}
            accept={this.logOut}
            title={<GetText id="logOutConfirmMessage" />}
          />
          <ChangePassword
            display={this.state.displayPasswordDialog}
            close={this.closePasswordDialog}
            accept={this.logOut}
          />
        </LanguageProvider>
      </Fragment>
    );
  }
  componentDidMount() {
    let email = localStorage.getItem("email_to_impersonate");

    if (email !== "null") {
      this.setState({
        email_to_impersonate: email,
      });
    }
  }
  setCollapsedContent = (val) => {
    this.setState({ collapsed: val });
  };
  openCalendly = () => {
    history.replace("/call");
  };
  showShareAccess = () => {
    this.setState({ shareAccessDialog: true });
  };
  closeAccessDialog = () => {
    this.setState({ shareAccessDialog: false });
  };
  closeConfirmationDialog = () => {
    this.setState({ displayConfirmationDialog: false });
  };
  promptLogOut = (language) => {
    let { logOut } = this;
    confirm({
      title:
        language == "en"
          ? "Are you sure you want to logout?"
          : "您确定要退出吗？",
      onOk() {
        logOut();
      },
    });
  };
  changePassword = () => {
    this.setState({ displayPasswordDialog: true });
  };
  closePasswordDialog = () => {
    this.setState({ displayPasswordDialog: false });
  };
  logOut = () => {
    let i = localStorage.length;
    while (i--) {
      let key = localStorage.key(i);
      if (!/_newFeature/.test(key)) {
        localStorage.removeItem(key);
      }
    }
    history.replace("/login");
  };
}

export default Content;
