import React, { useState } from "react";
import { Upload, Button } from "antd";
export default ({ setFile, loadingRequest }) => {
  let [fileListData, setFileList] = useState();
  const handleChange = ({ file, fileList }) => {
    setFileList([file]);
    const files = fileList;
    if (files && files[0]) {
      setFile(files[0].originFileObj);
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
  };
  return (
    <div>
      <Upload
        {...uploadProps}
        disabled={loadingRequest}
        accept={".xlsx"}
        onRemove={() => {
          setFile(null);
        }}
        method="get"
        fileList={fileListData}
        onChange={handleChange}
      >
        <Button disabled={loadingRequest} icon="upload">
          Upload File
        </Button>
      </Upload>
    </div>
  );
};
