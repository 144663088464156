import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import classes from "./PromotionResults.module.less";
import {
  Table,
  DatePicker,
  Button,
  Popconfirm,
  notification,
  Select,
  Card,
  ConfigProvider,
  Modal,
  Statistic,
  InputNumber
} from "antd";
import { getDefaultDate } from "../../functions";
import {
  getPromotionResults,
  updatePromotionResults,
} from "../../services/promotionResultsService";
import GetText from "../UI/Text/Text";
import { LanguageContext } from "../../context/languageContext";
import { getAllStores } from "../../services/storesService";
import XLSXModal from "./XLSXModal/XLSXModal";
import locale from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";
moment.locale("en");

const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;
const gridStyle = {
  width: "25%",
  textAlign: "center",
};

export default ({ appId, app }) => {
  let [loading, setLoading] = useState(false);
  const { language, dictionary } = useContext(LanguageContext);

  let [data, setData] = useState([]);
  let [store, setStore] = useState(null);
  let [changedData, setChangedData] = useState(false);
  let [dateRange, setDateRange] = useState(getDefaultDate());
  let [modalOpen, setModalOpen] = useState(false);
  let [storesOptions, setStoresOptions] = useState([]);
  let [selectedData, setSelectedData] = useState([]);
  let validationDate = () => {
    return notification.info({
      message: <GetText id="dateOutOfRangeError" />,
    });
  };
  let validateRange = (m) => {
    let diff = m[1].diff(m[0], "days");

    if (diff <= 60) {
      setDateRange(m);
    } else {
      validationDate();
    }
  };

  let updateValue = (value, column, id) => {
    // console.log("RES", value, column, id);
    let dataCopy = [...data];
    let rowIndex = dataCopy.findIndex((it) => it.id === id);
    dataCopy[rowIndex][column] = value;
    setData(dataCopy);
    setChangedData(true);
  };

  let saveData = async () => {
    let { data: response } = await updatePromotionResults({ appId, data });
    if (response && response.message === "success") {
      notification.success({
        message: <GetText id="successMessage" />,
        description: <GetText id="successUpdateMessage" />,
        placement: "bottomRight",
      });
    } else {
      notification.warn({
        message: <GetText id="errorMessage" />,
        description: <GetText id="errorUpdateMessage" />,
        placement: "bottomRight",
      })
    }
  };
  const columns = [
    {
      title: <GetText id="promotionResultsTableHeaders.date" />,
      dataIndex: "date",
      key: "date",
      fixed: "left",
      className: classes.date,
      width: "110px",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: <GetText id="promotionResultsTableHeaders.exposure" />,
      dataIndex: "exposure",
      key: "exposure",
      render: (e, it) => (
        <InputNumber
          value={e}
          max="2147483647"
          min="0"
          maxLength={9}
          onChange={(value) => updateValue(value, "exposure", it.id)}
        />
      ),
      align: "right",
    },
    {
      title: <GetText id="promotionResultsTableHeaders.downloads" />,
      dataIndex: "downloads",
      key: "downloads",
      render: (e, it) => (
        <InputNumber
          value={e}
          max="2147483647"
          min="0"
          maxLength={9}
          style={{ width: 120 }}
          onChange={(value) => updateValue(value, "downloads", it.id)}
        />
      ),
      align: "right",
    },
    {
      title: <GetText id="promotionResultsTableHeaders.amountSpent" />,
      dataIndex: "amount",
      key: "amount",
      render: (e, it) => (
        <InputNumber
          value={e}
          onChange={(value) => updateValue(value, "amount", it.id)}
        />
      ),
      align: "right",
    },
    {
      title: <GetText id="promotionResultsTableHeaders.price" />,
      dataIndex: "price_per_download",
      key: "price_per_download",
      render: (e, it) => (
        <InputNumber
          value={e}
          onChange={(value) => updateValue(value, "price_per_download", it.id)}
        />
      ),
      align: "right",
    },
    {
      title: <GetText id="promotionResultsTableHeaders.downloadRate" />,
      dataIndex: "download_rate",
      key: "download_rate",
      render: (e, it) => (
        <InputNumber
          value={e}
          onChange={(value) => updateValue(value, "download_rate", it.id)}
        />
      ),
      align: "right",
    },
  ];
  let makeRequest = async () => {
    if (store !== null) {
      setLoading(true);
      let { data: response } = await getPromotionResults({
        id: appId,
        store,
        start: dateRange[0].format("YYYY-MM-DD"),
        end: dateRange[1].format("YYYY-MM-DD"),
      });
      setLoading(false);
      if (response && response.expenses) {
        setData(response.expenses);
      }
    }
  };

  const lastDaysData = async (days) => {
    if (store !== null) {
      setLoading(true);
      const start = moment(dateRange[1]).subtract(days, "days");
      const end = dateRange[1];
      setDateRange([start, end]);
      let { data: response } = await getPromotionResults({
        id: appId,
        store,
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      });
      setLoading(false);
      if (response && response.expenses) {
        setData(response.expenses);
      }
    }
  };

  const getStoresValues = async () => {
    let { data: allStores } = await getAllStores();
    if (data) {
      let storesApps = app.stores.split(",");
      let storesSelected = storesApps.map((id) => {
        return allStores.filter((e) => e.id === id)[0].fullname;
      });
      setStoresOptions(storesSelected);
      setStore(storesSelected[0]);
    }
  };

  useEffect(() => {
    getStoresValues();
    makeRequest();
  }, []);

  useEffect(() => {
    makeRequest();
  }, [store, dateRange]);
  useEffect(() => {
    makeRequest();
    getStoresValues();
  }, [appId]);

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedData(selectedRowKeys);
    },
  };

  const showConfirmDeleteData = () => {
    confirm({
      title: dictionary.deleteData(selectedData.length),
      okText: dictionary.ok,
      cancelText: dictionary.cancel,
      onOk() {
        const result = data.map((it) =>
          selectedData.find((id) => id === it.id)
            ? {
              ...it,
              amount: null,
              download_rate: null,
              downloads: null,
              exposure: null,
              price_per_download: null,
            }
            : it
        );
        setData([...result]);
        setSelectedData([]);
        setChangedData(true);
      },
    });
  };

  let summary = data.reduce(
    (prev, curr) => ({
      exposure: curr.exposure
        ? prev.exposure + parseFloat(curr.exposure)
        : prev.exposure,
      downloads: curr.downloads
        ? prev.downloads + parseFloat(curr.downloads)
        : prev.downloads,
      amount_spent: curr.amount
        ? prev.amount_spent + parseFloat(curr.amount)
        : prev.amount_spent,
    }),
    {
      exposure: 0,
      downloads: 0,
      amount_spent: 0,
    }
  );

  return (
    <div className={classes.container}>
      <ConfigProvider locale={language.id == "ch" ? locale : "en"}>
        <div className={classes.controls}>
          <RangePicker
            loading={loading}
            disabled={loading}
            value={[dateRange[0], dateRange[1]]}
            onChange={(moment) => {
              validateRange(moment);
            }}
            format="YYYY-MM-DD"
            locale={locale}
          />
          <Button icon="reload" type="primary" onClick={() => lastDaysData(7)}>
            {" "}
            <GetText id="last7Days" />
          </Button>
          <Button icon="reload" type="primary" onClick={() => lastDaysData(30)}>
            {" "}
            <GetText id="last30Days" />
          </Button>
          <Select
            loading={loading}
            disabled={loading}
            value={store}
            style={{ width: 161 }}
            onChange={(newStore) => setStore(newStore)}
          >
            {storesOptions.map((it) => (
              <Option value={it}>{it}</Option>
            ))}
          </Select>
          <Popconfirm
            placement="bottom"
            title={<GetText id="updateConfirmationMessage" />}
            onConfirm={saveData}
            okText={<GetText id="ok" />}
            cancelText={<GetText id="cancel" />}
          >
            <Button
              icon="save"
              type="primary"
              disabled={!changedData || loading}
            >
              {" "}
              <GetText id="saveChangesButton" />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            icon="delete"
            onClick={showConfirmDeleteData}
            disabled={selectedData.length == 0}
          >
            {" "}
            <GetText id="deleteButton" />
          </Button>
        </div>
        <Card title="Summary" style={{ marginBottom: 10 }} loading={loading}>
          <Card.Grid style={gridStyle}>
            <Statistic title="Exposure" value={summary.exposure.toFixed(0)} />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic title="Downloads" value={summary.downloads} />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="Amount Spent (¥)"
              value={summary.amount_spent.toFixed(2)}
            />
          </Card.Grid>
          <Card.Grid style={gridStyle}>
            <Statistic
              title="Price (¥)"
              value={
                summary.downloads
                  ? (summary.amount_spent / summary.downloads).toFixed(2)
                  : 0
              }
            />
          </Card.Grid>
        </Card>
        <Card
          title={<GetText id="dataPerDayTitle" />}
          extra={
            <Button
              disabled={loading}
              icon="cloud-upload"
              onClick={() => setModalOpen(true)}
            >
              <GetText id="uploadXlsx" />
            </Button>
          }
        >
          <Table
            selectedRowKeys={selectedData}
            rowSelection={rowSelection}
            loading={loading}
            dataSource={data}
            columns={columns}
            rowKey="id"
            size="small"
            scroll={{ x: 800 }}
          />
        </Card>
        <XLSXModal
          visible={modalOpen}
          closeModal={() => setModalOpen(false)}
          actualData={data}
          updateData={setData}
          appId={appId}
          store={store}
        />
      </ConfigProvider>
    </div>
  );
};
