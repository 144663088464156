import React, { Component, Fragment } from "react";
import EventBus from "eventbusjs";
import { notification } from "antd";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import Login from "../Login/Login";
import Content from "../Content/Content";
import { getApps } from "../../services/appsService";
import Auth from "../../auth";
import history from "../../history";
import OptimizationInformation from "../OptimizationInformation/OptimizationInformation";
import PromotionResults from "../PromotionResults/PromotionResults";
import Attachments from "../Attachments/Attachments";
import Settings from "../Settings/Settings";
import BigLoader from "../UI/BigLoader/BigLoader";
import LogOutNotification from "../UI/LogOutNotification/LogOutNotification";
import GetText from "../UI/Text/Text";
const auth = new Auth();
class Home extends Component {
  state = {
    preventRedirect: false,
    loading: true,
    apps: null,
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
    });
  };
  getData = () => {
    setTimeout(this.getApps, 500);
    if (localStorage.redirect) {
      if (!localStorage.redirect.includes("login")) {
        history.push(localStorage.redirect);
      }
      localStorage.removeItem("redirect");
    }
  };

  handleLoginError = () => {
    //trying again
    let email = localStorage.e;
    let password = localStorage.p;

    localStorage.removeItem("e");
    localStorage.removeItem("p");

    if (email && password) {
      auth.login(email, password).then((err, result) => {
        this.setState({
          loading: false,
        });
      });
    } else {
      this.setState({ loading: false });
      this.openNotificationWithIcon(
        "warning",
        "Connection error, please try again"
      );
    }
  };

  handleAuthentication = () => {
    let pathname = window.location.pathname;
    if (/access_token|id_token|error/.test(window.location.hash)) {
      auth.handleAuthentication();
    } else if (/jwt/.test(window.location.search)) {
      let url_params = new URLSearchParams(window.location.search);
      localStorage.original_id_token = localStorage.id_token;
      localStorage.id_token = url_params.get("jwt");
      localStorage.email_to_impersonate = decodeURIComponent(
        url_params.get("email_to_impersonate")
      );
      localStorage.email_website = decodeURIComponent(
        url_params.get("email-website")
      );
      localStorage.expires_at =
        url_params.get("expires_at") ||
        new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000).getTime();
      setTimeout(this.getApps, 500);
    } else if (!auth.isAuthenticated() && !pathname.includes("unsuscribe")) {
      if (!pathname.includes("login")) {
        localStorage.redirect = pathname;
        history.replace("/login");
      }
      this.setState({ loading: false });
    } else if (!pathname.includes("login")) {
      this.getApps();
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    let { apps, loading } = this.state;
    return (
      <Fragment>
        <Router history={history}>
          <Switch>
            <Route
              key="login"
              path="/login"
              exact
              render={(props) => <Login {...props} />}
            />
            <Route
              key="home"
              path="/"
              exact
              render={(props) => <Redirect to={`/promotion-results/`} />}
            />
            <Route
              key="downloads"
              path="/optimization-information/:app_id/"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="optimization-information"
                  section="optimization-information"
                  appId={props.match.params.app_id}
                  title={<GetText id="optimizationInformationTitle" />}
                >
                  <OptimizationInformation
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    {...props}
                    appId={props.match.params.app_id}
                  />
                </Content>
              )}
            />
            <Route
              key="downloads"
              path="/promotion-results/:app_id/"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="promotion-results"
                  section="promotion-results"
                  appId={props.match.params.app_id}
                  title={<GetText id="promotionResultsTitle" />}
                >
                  <PromotionResults
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    {...props}
                    appId={props.match.params.app_id}
                  />
                </Content>
              )}
            />
            <Route
              key="downloads"
              path="/attachments/:app_id/"
              exact
              render={(props) => (
                <Content
                  apps={apps}
                  menuOption="attachments"
                  section="attachments"
                  appId={props.match.params.app_id}
                  title={<GetText id="attachmentsTitle" />}
                >
                  <Attachments
                    app={
                      apps
                        ? apps.find((it) => it.id === props.match.params.app_id)
                        : null
                    }
                    appId={props.match.params.app_id}
                    {...props}
                  />
                </Content>
              )}
            />
            <Route
              key="settings"
              path="/settings/:section/:app_id"
              exact
              render={(props) => (
                <Content
                  appId={props.match.params.app_id}
                  apps={apps}
                  hideAppSelector
                  title="Settings"
                  section={props.match.params.section}
                >
                  <Settings
                    {...props}
                    appId={props.match.params.app_id}
                    apps={apps}
                    section={props.match.params.section}
                  />
                </Content>
              )}
            />
            <Route
              key="other"
              path="/"
              render={() =>
                !loading && apps ? (
                  apps.length > 0 ? (
                    <Redirect to={`/promotion-results/${apps[0].id}`} />
                  ) : (
                    // <Redirect to={`/promotion-results/`} />
                    <LogOutNotification />
                  )
                ) : (
                  <BigLoader loading />
                )
              }
            />
          </Switch>
        </Router>
      </Fragment>
    );
  }

  handleUpdateSubmission = () => {
    this.openNotificationWithIcon("info", "New version submitted successfully");
    window.history.back();
  };

  handleCopyrightSubmission = (event) => {
    let app = {
      ...this.state.apps.find((it) => it.selected),
      copyright_form_submitted: true,
      copyright_form_skipped: true,
    };
    let apps = [...this.state.apps];
    apps[apps.findIndex((it) => app.id === it.id)] = app;
    this.setState({
      apps: apps,
    });
  };

  updateApps = (event, apps, preventReload = false) => {
    this.setState({
      apps: [...apps],
      appsDownloaded: true,
      loading: false,
    });
    if (!preventReload) history.push("/");
  };

  updateApp = (event, app) => {
    let apps = [...this.state.apps];
    if (apps && apps.length) {
      let index = apps.findIndex(
        (it) => app.FK_Apps === it.FK_Apps || app.id === it.id
      );
      apps[index] = { ...app };
    }
    this.setState({
      apps: apps,
      loading: false,
    });
  };

  selectApp = (event, index) => {
    let apps = [...this.state.apps];
    apps.forEach((it, i, arr) => (arr[i].selected = i === index));
    this.setState({ apps: apps, addingApp: false });
  };

  getApps = async () => {
    this.setState({ loading: true });
    let response = await getApps();
    if (response.data && response.data.apps) {
      this.setState({
        feedback_needed: !!response.data.feedback_needed,
      });
      this.updateApps(null, response.data.apps, true);
    } else {
      this.openNotificationWithIcon("warning", "An error happened");
    }
  };

  componentWillMount() {
    this.handleAuthentication();
  }

  componentDidMount() {
    EventBus.addEventListener("UPDATE_APPS", this.updateApps, this);
    EventBus.addEventListener("UPDATE_APP", this.updateApp, this);
    EventBus.addEventListener("SELECT_APP", this.selectApp, this);
    EventBus.addEventListener("GET_APPS", this.getApps, this);
    EventBus.addEventListener("GET_DATA", this.getData, this);
    EventBus.addEventListener("LOGIN_ERROR", this.handleLoginError, this);
  }

  componentWillUnmount() {
    EventBus.removeEventListener("UPDATE_APPS", this.updateApps, this);
    EventBus.removeEventListener("UPDATE_APP", this.updateApp, this);
    EventBus.removeEventListener("SELECT_APP", this.selectApp, this);
    EventBus.removeEventListener("GET_APPS", this.getApps, this);
    EventBus.removeEventListener("GET_DATA", this.getData, this);
    EventBus.removeEventListener("LOGIN_ERROR", this.handleLoginError, this);
  }
}

export default Home;
