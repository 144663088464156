import en from "./en";
import ch from "./ch";

export const dictionaryList = {
  en,
  ch,
};

export const languageOptions = [
  { id: "ch", text: "中文" },
  { id: "en", text: "English" },
];
