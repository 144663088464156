import React, { createContext, useContext, useEffect, useState } from "react";
import { languageOptions, dictionaryList } from "../languages";

const [EN] = languageOptions;
export const LanguageContext = createContext({
  language: EN,
  dictionary: dictionaryList[EN.id],
});

export const LanguageConsumer = LanguageContext.Consumer;

export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);
  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
    },
  };
  const getLocalLanguage = () => {
    try {
      const lang = localStorage.getItem("language");
      if (lang) {
        if (lang == "en") {
          setLanguage({ id: "en", text: "English" });
          setDictionary(dictionaryList["en"]);
        } else {
          setLanguage({ id: "ch", text: "中文" });
          setDictionary(dictionaryList["ch"]);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getLocalLanguage();
  }, []);

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
}
