import React, { useState, useEffect } from "react";
import classes from "./OptimizationInformation.module.less";
import {
  Table,
  Input,
  DatePicker,
  Button,
  Popconfirm,
  notification,
  Select,
  Card,
} from "antd";
import {
  getOptimization,
  updateOptimization,
} from "../../services/optimizationInformationService";
import { getAllStores } from "../../services/storesService";
import { getDefaultDate } from "../../functions";
import GetText from "../UI/Text/Text";
const { RangePicker } = DatePicker;
const { Option } = Select;

export default ({ appId, app }) => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [store, setStore] = useState(null);
  let [storesOptions, setStoresOptions] = useState([]);
  let [changedData, setChangedData] = useState(false);
  let [dateRange, setDateRange] = useState(getDefaultDate());
  const columns = [
    {
      title: <GetText id="optimizationInformationTableHeaders.date" />,
      dataIndex: "date",
      key: "date",
      className: classes.date,
      width: "110px",
    },
    {
      title: (
        <GetText id="optimizationInformationTableHeaders.optimizationSummary" />
      ),
      dataIndex: "optimization_summary",
      key: "optimization_summary",
      render: (e, _, index) => (
        <Input.TextArea
          value={e}
          onChange={(e) =>
            updateValue(e.target.value, "optimization_summary", index)
          }
        />
      ),
    },
    {
      title: (
        <GetText id="optimizationInformationTableHeaders.optimizationPlan" />
      ),
      dataIndex: "optimization_plan",
      key: "optimization_plan",
      render: (e, _, index) => (
        <Input.TextArea
          value={e}
          onChange={(e) =>
            updateValue(e.target.value, "optimization_plan", index)
          }
        />
      ),
    },
  ];
  let updateValue = (value, column, rowIndex) => {
    let dataCopy = [...data];
    dataCopy[rowIndex][column] = value;
    setData(dataCopy);
    setChangedData(true);
  };
  let validationDate = () => {
    return notification.info({
      message: <GetText id="dateOutOfRangeError" />,
    });
  };
  let validateRange = (m) => {
    let diff = m[1].diff(m[0], "days");
    if (diff <= 60) {
      setDateRange(m);
    } else {
      validationDate();
    }
  };
  let saveData = async () => {
    let { data: response } = await updateOptimization({ appId, data });
    if (response && response.message === "success") {
      notification.success({
        message: <GetText id="successMessage" />,
        description: <GetText id="successUpdateMessage" />,
        placement: "bottomRight",
      });
    }
  };

  let makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getOptimization({
      id: appId,
      store,
      start: dateRange[0].format("YYYY-MM-DD"),
      end: dateRange[1].format("YYYY-MM-DD"),
    });
    setLoading(false);
    if (response && response.optimizationInfo && response.stores) {
      setData(response.optimizationInfo);
    }
  };
  const getStoresValues = async () => {
    let { data: allStores } = await getAllStores();
    if (data) {
      let storesApps = app.stores.split(",");
      let storesSelected = storesApps.map((id) => {
        return allStores.filter((e) => e.id === id)[0].fullname;
      });
      setStoresOptions(storesSelected);
      setStore(storesSelected[0]);
    }
  };
  useEffect(() => {
    makeRequest();
    getStoresValues();
  }, []);

  useEffect(() => {
    makeRequest();
  }, [store, dateRange]);
  useEffect(() => {
    makeRequest();
    getStoresValues();
  }, [appId]);

  return (
    <div className={classes.container}>
      <div className={classes.controls}>
        <RangePicker
          value={[dateRange[0], dateRange[1]]}
          onChange={(moment, string) => {
            validateRange(moment);
          }}
          disabled={loading}
          loading={loading}
          format="YYYY-MM-DD"
        />
        <Select
          disabled={loading}
          loading={loading}
          value={store}
          style={{ width: 200 }}
          onChange={(newStore) => setStore(newStore)}
        >
          {storesOptions.map((it) => (
            <Option value={it}>{it}</Option>
          ))}
        </Select>
        <Popconfirm
          placement="bottom"
          title={<GetText id="updateConfirmationMessage" />}
          onConfirm={saveData}
          okText={<GetText id="ok" />}
          cancelText={<GetText id="cancel" />}
        >
          <Button icon="save" type="primary" disabled={!changedData || loading}>
            <GetText id="saveChangesButton" />
          </Button>
        </Popconfirm>
      </div>
      <Card title={<GetText id="dataPerDayTitle" />}>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          size="small"
          pagination={{ pageSize: 5 }}
        />
      </Card>
    </div>
  );
};
