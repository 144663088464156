import React, { useState, useEffect } from "react";
import Axios from "axios";
import classes from "./Attachments.module.less";
import { Upload, Icon, Modal, Card, Button, notification, Row } from "antd";
import {
  getStoresAttachments,
  updateStoresAttachments
} from "../../services/attachmentsService";
import GetText from "../UI/Text/Text";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const { confirm } = Modal;
export default ({ appId, app }) => {
  let [previewVisible, setPreviewVisible] = useState(false);
  let [previewImage, setPreviewImage] = useState("");
  let [uploadingImg, setUploadingImg] = useState(false);
  let [saving, setSaving] = useState(false);
  let [loading, setLoading] = useState(false);
  let [stores, setStores] = useState([]);
  let [changedData, setChangedData] = useState(false);
  let handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const uploadImage = async (
    { file, onSuccess, onProgress, onError },
    index
  ) => {
    const timeStamp = new Date().getTime();
    const formData = new FormData();
    const result = `${timeStamp}/${file.name}`;
    formData.set("uploads[]", file);
    formData.set("name", file.name);
    formData.set("timestamp", timeStamp);
    setUploadingImg(true);
    let { data } = await Axios.post("https://appinchina.space/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress({
          percent: percentCompleted
        });
      }
    });
    setUploadingImg(false);
    if (data) {
      onSuccess(result);

      let newAttachment = {
        uid: new Date().getTime(),
        name: file.name,
        size: file.size,
        url: `https://appinchina.space/uploads/${result}`
      };
      newAttachentState(newAttachment, index);
    } else {
      onError("error");
    }
  };

  const newAttachentState = (newFile, index) => {
    let storesCopy = [...stores];
    let act = storesCopy[index].fileList;
    storesCopy[index].fileList = [...act, { ...newFile }];
    setChangedData(true);
    setStores(storesCopy);
  };
  const removeFile = (fileId, index) => {
    let storesCopy = [...stores];
    let act = storesCopy[index].fileList;
    act = act.filter(e => e.uid !== fileId);
    storesCopy[index].fileList = [...act];
    setChangedData(true);
    setStores(storesCopy);
  };
  const confirmDelete = (fileId, index) => {
    confirm({
      title: <GetText id="deleteAttachmentConfirmationMessage" />,
      onOk() {
        removeFile(fileId, index);
      },
      onCancel() {}
    });
  };

  const uploadButton = (
    <div>
      <Icon type={uploadingImg || saving ? "loading" : "plus"} />
      <div className="ant-upload-text">
        <GetText id="uploadButton" />
      </div>
    </div>
  );
  const getData = async () => {
    setLoading(true);
    let { data: response } = await getStoresAttachments(appId, app.stores);
    setLoading(false);
    if (response && response.result) {
      setStores(response.result);
    }
  };
  const makeRequest = async () => {
    setSaving(true);
    let { data: response } = await updateStoresAttachments({
      appId,
      stores
    });
    setSaving(false);
    if (response && response.message === "success") {
      notification.success({
        message: <GetText id="successMessage" />,
        description: <GetText id="informationUpdatedSuccess" />,
        placement: "bottomRight"
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [appId]);

  return (
    <div className={classes.container}>
      <Row type="flex" justify="end">
        <Button
          className={classes.saveButton}
          type="primary"
          onClick={() => makeRequest()}
          disabled={!changedData || loading}
          icon={saving ? "loading" : "save"}
        >
          {saving ? (
            <GetText id="saving" />
          ) : (
            <GetText id="saveChangesButton" />
          )}
        </Button>
      </Row>
      {stores.map((it, key) => {
        return (
          <Card title={it.name} key={key}>
            <Upload
              disabled={uploadingImg || saving}
              listType="picture-card"
              onRemove={file => confirmDelete(file.uid, key)}
              fileList={it.fileList}
              onPreview={handlePreview}
              customRequest={values => uploadImage(values, key)}
            >
              {it.fileList.length >= 8 ? null : uploadButton}
            </Upload>
          </Card>
        );
      })}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};
