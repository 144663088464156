import React, { useContext, useEffect } from "react";
import { Menu } from "antd";
import { languageOptions } from "../../../languages";
import { LanguageContext } from "../../../context/languageContext";
const { Item } = Menu;

export default function LanguageSelector() {
  const { setLanguage, language } = useContext(LanguageContext);

  const handleLanguageChange = ({ key }) => {
    const selectedLanguage = languageOptions.find((item) => item.id === key);
    setLanguage(selectedLanguage);
    localStorage.removeItem("language");
    localStorage.setItem("language", selectedLanguage.id);
  };

  return (
    <Menu onSelect={handleLanguageChange} selectedKeys={[language.id]}>
      {languageOptions.map((item) => (
        <Item key={item.id}>{item.text}</Item>
      ))}
    </Menu>
  );
}
