const ch = {
  promotionResultsTitle: "推广结果",
  optimizationInformationTitle: "优化信息",
  attachmentsTitle: "附件",
  saveChangesButton: "保存修改",
  uploadXlsx: "上传 XLSX",
  uploadXlsxTitle: "上传 XLSX",
  uploadXlsxError: "XLSX file invalid format",
  uploadXlsxSuccess: "Table values updated from file",
  dataPerDayTitle: "每日数据",
  deleteButton: "删除",
  last7Days: "最近7天",
  last30Days: "最近30天",
  saving: "Saving...",
  deleteData: days => `您确定要清除 ${days} 天的数据吗？`,
  promotionResultsTableHeaders: {
    date: "日期",
    exposure: "曝光",
    downloads: "下载量",
    amountSpent: "花费金额",
    price: "价格",
    downloadRate: "下载率",
  },
  optimizationInformationTableHeaders: {
    date: "日期",
    optimizationSummary: "优化总结",
    optimizationPlan: "优化计划",
  },
  uploadButton: "Upload",
  settingsMenuItem: "设置",
  logOutMenuItem: "设置",
  profileTitle: "Profile",
  firstNameTitle: "First Name",
  lastNameTitle: "Last Name",
  emailTitle: "Email",
  profileImageTitle: "Profile Image",
  changePasswordButton: "Change Password",
  saveButton: "Save",
  successMessage: "Success",
  successUpdateMessage: "The information has been updated in the database.",
  errorMessage: "Error",
  errorUpdateMessage: "An error occured while saving the data",
  logOutConfirmMessage: "您确定要退出吗？",
  updateConfirmationMessage: "您确定要保存所有信息吗？",
  dateOutOfRangeError: "Range should not be greater than 60 days",
  deleteAttachmentConfirmationMessage:
    "Are you sure you want to delete this attachment?",
  sendingInformation: "Sending Information...",
  sendInformation: "Send",
  informationUpdatedSuccess: "Information updated!",
  ok: "确认",
  cancel: "取消",
};

export default ch;
